<template>
  <main>
    <!-- <img src="@/assets/product-features.png" alt="product-features" />

    <section>
      <h1>Product Features</h1>

      <aside>
        <article>

        </article>
      </aside>
    </section> -->
  </main>
</template>

<script setup></script>

<style lang="scss" scoped></style>
